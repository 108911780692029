import { useEffect } from "react";
import { isAndroid, isIOS, isPWA } from "~/utils/device-check";
import metrics from "~/utils/metrics";

export default function usePageView(
  pageName: string,
  extraProperties: { [key: string]: any } = {},
) {
  return useEffect(() => {
    if (isPWA()) {
      extraProperties["pwa"] = true;
      extraProperties["pwa-platform"] = "web";
      if (isAndroid()) {
        extraProperties["pwa-platform"] = "android";
      }
      if (isIOS()) {
        extraProperties["pwa-platform"] = "ios";
      }
      metrics.track("pwa_used", extraProperties);
    }
    metrics.pageView(pageName, extraProperties);
  }, []);
}
