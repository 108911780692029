import mobile from "is-mobile";

export const isAndroid = () => {
  if (typeof window === "undefined") {
    return false;
  }
  return /Android/i.test(navigator.userAgent);
};
export const isIOS = () => {
  if (typeof window === "undefined") {
    return false;
  }
  return (
    /iPhone|iPad/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1)
  );
};

export const isMobile = () => {
  return mobile({
    tablet: true,
    featureDetect: true,
  });
};

export const isPWA = () => {
  if (typeof window === "undefined") {
    return false;
  }
  return window?.matchMedia("(display-mode: standalone)").matches;
};
